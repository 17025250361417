<template>
    <div>
        <van-empty
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        description="訂單爲空"
        v-if="orderdata.length === 0"
        />
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
            <v-order v-for="(child,index) in orderdata" :key="index" :order-data="child" :configure="configure" @cencalorder="cencalOrder" :type="false" ></v-order>
        </van-list>
    </div>
</template>
<script>
import {orderList} from "@/api/order"
import vOrder from "@/components/order"
export default {
    name:"order",
    components: {
        vOrder
    },
    data() {
        return {
            activeName: 1,
            keyword:'',
            orderdata:[],
            loading:false,
            finished:false,
            page: 0,
            limit: 10,
            configure: {
                time:true,
                title:false,
                columns:[
                    {
                        prop:'detailList',
                        label:'',
                        disabled: true,
                        type: 'img'
                    },
                    {
                        prop:'dealAmount',
                        label:'訂單總金額',
                        labelEn:'Grand Total',
                        disabled: true,
                    },
                    {
                        prop:'online',
                        label:'在綫支付金額',
                        labelEn:'Online total',
                        disabled: true,
                    },
                ]
            }
        }
    },
    created() {
    },
    methods: {
        getOrder() {
            let parms = {
                "limit": this.limit,
                "page": this.page
            }
            orderList(parms).then((res) => {
                this.loading = false;
                this.orderdata = this.orderdata.concat(res.data.list);
                if (res.data.list.length  == 0) {  //数据全部加载完成
                    this.finished = true;
                }else{
                    this.finished = false;
                }
            })
        },
        onLoad() {
            this.page++;
            this.getOrder(); 
        },
        cencalOrder() {
            this.orderdata = [];
            this.page = 1;
            this.getOrder();
        }
    }
}
</script>
<style scoped lang="scss">
    .main{
      font-size: 16px;
    }
    /deep/ .van-button--mini{
      font-size: 16px;
    }
    .content {
        min-height:568px;
        overflow-y:auto;
        position: relative;
    }
    .delete-button {
        height: 100%;
    }
</style>
